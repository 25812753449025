<template>
	<a-autocomplete
		:placeholder='placeholder'
		:value='value'
		@input='$emit("input", $event)'
		:requestName='requestName'
		:prepend-icon='prependIcon'
		item-value='code'
		:errors='errors'
		return-object
	>
		<template v-slot:dropdown="{ items, update, closeSelect, value }">
			<ul class='a-select-search__dropdown--list'>
				<li v-for="(option, i) of items" :key='i'>
					<div class='a-select-search__dropdown--item'
						:class='{"active": value ? option.code === value.code : null}'
						@click.stop="update(option), closeSelect()"
					>
						<div class='a-select-search__dropdown--icon'>
							<v-icon :icon='findIcon(option.type)' size='16' />
						</div>
						<div class='a-select-search__dropdown--content'>
							<p class='a-select-search__dropdown--title'>{{ option[itemName] }}</p>
							<p class='a-select-search__dropdown--sub-title' v-if='option.type !== "destination"'>{{ option.location }}</p>
						</div>
						<div class='a-select-search__dropdown--action ml-auto' v-if='isCode && option.type === "terminal"'>
							<p class="a-select-search__dropdown--code">{{option.code}}</p>
						</div>
					</div>
				</li>
			</ul>
		</template>
	</a-autocomplete>
</template>

<script>
	export default {
		props: {
			value: [String, Object],
			placeholder: String,
			"prepend-icon": {
				type: [String, Boolean],
				default: false
			},
			errors: [Boolean, Array],
			requestName: Function,
			"item-name": {
				type: String,
				default: 'name'
			},
			isCode: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			findIcon(icon) {
				switch (icon) {
					case 'hotel':
					case 'hotelCode':
						return 'house';
					case 'destination':
					case 'terminal':
						return 'pin';
					case 'zone':
						return 'priority';
					default:
						return 'air';
				}
			}
		}
	}
</script>
